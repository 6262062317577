@font-face {
  font-family: sunrunSerif;
  src: url(/fonts/serif-regular.ttf);
}

@font-face {
  font-family: sunrunSerif;
  src: url(/fonts/serif-bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: sunrunSerif;
  src: url(/fonts/serif-bold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: sunrunSerif;
  src: url(/fonts/serif-medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: sunrunSanSerif;
  src: url(/fonts/sans-regular.ttf);
}

@font-face {
  font-family: sunrunSanSerif;
  src: url(/fonts/sans-bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: sunrunSanSerif;
  src: url(/fonts/sans-bold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: sunrunSanSerif;
  src: url(/fonts/sans-medium.ttf);
  font-weight: 500;
}

body {
  margin: 0;
  font-family: sunrunSanSerif, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
}

:root {
  font-size: 16px;
}

* {
  /*border: 1px solid red;*/
}